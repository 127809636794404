import * as React from "react"
import {Helmet} from "react-helmet"
import {Layout} from "../components/Layout";
import {BlogSidebar} from "../components/BlogSidebar";
import SEO from "../components/seo";
import {graphql} from "gatsby";
import {stripEndingSlash} from "../util/stripEndingSlash";
import "../main.scss"

const categories = ["weekly-update", "dev", "music", "art"];

export default function Blog({location, data}) {


    const siteTitle = data.site.siteMetadata.title;
    const posts = data.allMarkdownRemark.nodes;

    const postsByCategory = categories.map(c => {
       return {
           "category": c,
           "posts": []
       }
    });

    posts.forEach(post => {
        const category = post.frontmatter.category;
        const byCategory = postsByCategory.find(c => c.category == category);
        byCategory?.posts.push(post);
    });

    return <>
        <SEO
            title={"Blog"}
            description={"Devblog for upcoming video game Dragon Drummer"}
        />
        <Layout title={"Dragon Drummer Blog"} pathname={location.pathname}>
            <div className="columns">
                <div className="column">
                    {postsByCategory.map(byCategory => {
                        return <div key={byCategory.category}>
                            <h1 className={"title is-4 is-spaced"}>
                                <a id={byCategory.category} style={{
                                }} href={"#" + byCategory.category}>#</a>
                                <span>
                                   {byCategory.category}
                                </span>
                            </h1>

                            <ol style={{ listStyle: `none` }}>
                            {byCategory.posts.map(post => {
                                const title = post.frontmatter.title || post.fields.slug
                                return (
                                    <li key={post.fields.slug}>
                                        <article
                                            className="post-list-item"
                                            itemScope
                                            itemType="http://schema.org/Article"
                                        >
                                            <header>
                                                <h2>
                                                    <a href={stripEndingSlash(location.pathname) + post.fields.slug}>
                                                        <span itemProp="headline">{title}</span>
                                                    </a>
                                                </h2>
                                                <small>{post.frontmatter.date}</small>
                                            </header>
                                            {/*<section>*/}
                                            {/*<p*/}
                                            {/*dangerouslySetInnerHTML={{*/}
                                            {/*__html: post.frontmatter.description,*/}
                                            {/*}}*/}
                                            {/*itemProp="description"*/}
                                            {/*/>*/}
                                            {/*</section>*/}
                                        </article>
                                    </li>
                                )
                            })}
                            </ol>
                            <hr/>
                        </div>

                    })}
                </div>
                <BlogSidebar profileImage={data.pigprofile}/>
            </div>

        </Layout>

    </>
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          category
        }
      }
    }
    pigprofile: file(relativePath: { eq: "pig_profile.png" }) {
      childImageSharp {
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`